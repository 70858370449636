import Logo from './logo.png';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  logo_width: '225px',
  logo_width_auth: '500px',
};

export default config;
